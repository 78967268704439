import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import moment from 'moment';

import { Line } from '../utility/line';

import ContentContainer from '../global/contentContainer';
import Title from '../text/title';
import Subtitle from '../text/subtitle';
import Textbox from '../text/textbox';
import SingleImage from '../images/singleImage';
import { ListLocationEvents } from '../events/listLocationEvents';
import DummyEvent from '../icons/dummy-event';

const Theme = {
  highlightColor: '#00aa90',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
};

const EventsContainer = styled.div`
  margin: 75px 0;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

// const Location = ({ data }) => {

class Location extends React.Component {
  constructor(props) {
    super(props);
    let currentTime = moment();

    this.state = {
      relatedEvents: undefined,
      activePrice: 'full_price',
      numberOfDummyEvents: 0,
      currentTime: currentTime,
    };
  }

  componentDidMount(props) {
    if (
      this.props.data.allPrismicEvent &&
      this.props.data.allPrismicEvent.edges.length >= 1
    ) {
      let formattedAutoEvents = this.props.data.allPrismicEvent.edges.filter(
        product => {
          let eventDateTime = moment(
            product.node.data.rawDate + ' ' + product.node.data.start_time
          );

          let pastEventCheck = moment(this.state.currentTime).isSameOrAfter(
            eventDateTime
          );

          return pastEventCheck === false;
        }
      );

      this.setState(
        {
          relatedEvents: formattedAutoEvents,
        },
        () => {
          if (this.state.relatedEvents.length < 4) {
            let numberOfDummyEvents = 4 - this.state.relatedEvents.length;
            this.setState({
              numberOfDummyEvents: numberOfDummyEvents,
            });
          }
        }
      );
    }
  }

  render(props, location) {
    let dummyEvents = [];
    if (this.state && this.state.numberOfDummyEvents >= 1) {
      for (let i = 0; i < this.state.numberOfDummyEvents; i++) {
        dummyEvents.push(
          <Col col={6} sm={6} lg={3} key={i}>
            <DummyEvent fill={Theme.highlightColor} />
          </Col>
        );
      }
    }

    return (
      <>
        <Helmet>
          <title>
            {this.props.data.prismicLocation.data.title.text +
              ', ' +
              this.props.data.prismicLocation.data.subtitle.text}
          </title>

          <meta
            name="title"
            content={
              this.props.data.prismicLocation.data.title.text +
              ', ' +
              this.props.data.prismicLocation.data.subtitle.text
            }
          />
          <meta
            name="description"
            content={this.props.data.prismicLocation.data.text.text}
          />
          <meta
            property="og:url"
            content={
              'https://www.draw-brighton.co.uk' + this.props.location.pathname
            }
          />
          <meta
            property="og:image"
            content={this.props.data.prismicLocation.data.image.url}
          />
          <meta
            property=" og:image:secure_url"
            content={this.props.data.prismicLocation.data.image.url}
          />
          <meta
            property="og:description"
            content={this.props.data.prismicLocation.data.text.text}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:locale" content="en" />
          <meta
            name="twitter:title"
            content={
              this.props.data.prismicLocation.data.title.text +
              ', ' +
              this.props.data.prismicLocation.data.subtitle.text
            }
          />
          <meta
            name="twitter:description"
            content={this.props.data.prismicLocation.data.text.text}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content={this.props.data.prismicLocation.data.image.url}
          />
        </Helmet>

        <Row justifyContent="center" css={ContainerTheme}>
          <Col col={12} md={10}>
            <ContentContainer
              padding={'0 36px'}
              mobilePadding={'40px 9px 0 9px'}
            >
              <Title text={this.props.data.prismicLocation.data.title.text} />
              <Subtitle
                text={this.props.data.prismicLocation.data.subtitle.text}
                mobileMargin={'20px 9px 40px'}
              />
            </ContentContainer>
          </Col>

          <Col col={12} md={10} lg={8}>
            <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
              <SingleImage
                image={this.props.data.prismicLocation.data.image.url}
              />
            </ContentContainer>
          </Col>

          <Textbox
            text={this.props.data.prismicLocation.data.text.html}
            moreInformation={`For upcoming courses in ${
              this.props.data.prismicLocation.data.title.text
            }, see below...`}
            moreInformationTextColor={Theme.highlightColor}
            bottomMargin={'20px 0 0 0'}
            mobileBottomMargin={'0 9px'}
          />

          <Col col={12}>
            <Line
              margin={'75px 36px 0'}
              mobileMargin={'0 9px 0'}
              color={Theme.highlightColor}
            />
          </Col>

          {this.state.relatedEvents !== undefined && (
            <Col col={12}>
              <ContentContainer padding={'0 36px'}>
                <EventsContainer>
                  <Row>
                    <ListLocationEvents
                      color={Theme.highlightColor}
                      textColor={Theme.textColor}
                      allEvents={this.state.relatedEvents}
                    />

                    {this.state.numberOfDummyEvents < 4 && dummyEvents}
                  </Row>
                </EventsContainer>
              </ContentContainer>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default Location;

export const pageQuery = graphql`
  query LocationBySlug($uid: String!) {
    prismicLocation(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        subtitle {
          text
        }
        image {
          url
        }
        text {
          html
          text
        }
      }
    }
    allPrismicEvent(
      filter: {
        data: { location: { uid: { eq: $uid } }, date: { gte: "2024-09-16" } }
      }
      sort: { order: ASC, fields: [data___date, data___start_time] }
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            title {
              text
            }
            image {
              alt
              fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                srcWebp
                srcSetWebp
              }
            }
            eventbrite_link {
              url
            }
            partner_event
            drawing_retreat
            rawDate: date
            date(formatString: "ddd, DD MMM ‘YY")
            rawEndDate: end_date
            end_date(formatString: "ddd, DD MMM ‘YY")
            tutor {
              uid
              document {
                ... on PrismicTutors {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              uid
              document {
                ... on PrismicLocation {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            start_time
            finish_time
            full_price
            discounted_price
          }
        }
      }
    }
  }
`;
